"use client";

import React from "react";
import { Toaster } from "@irbano/react-components/dist/components/ui/toast";

export type ClientProvidersProps = {
  children: React.ReactNode;
};

export const ClientProviders = ({ children }: ClientProvidersProps) => {
  return (
    <>
      {children}
      <Toaster />
    </>
  );
};
